import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import AppBar from "../../../components/AppBar";
import Card from "../../../components/Card";

import axios from "../../../constants/axios";

import getToken from "../../../functions/getToken";

interface Enterprise {
  name: string;
}

interface State {
  verified?: boolean;
}

export default function Home() {
  let [enterprise, setEnterprise] = useState<Enterprise>(),
    [showNotVerified, setShowNotVerified] = useState(false),
    location = useLocation<State>(),
    history = useHistory();

  const getData = async () => {
    axios
      .get("/enterprise/list/user/enterprise", {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        if (!data.enterprise.verified) {
          setShowNotVerified(true);
        }
        setEnterprise(data.enterprise);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (location?.state?.verified === false) {
      return setShowNotVerified(true);
    }
    getData();
  }, [location]);

  useEffect(() => {
    if (enterprise) {
      history.push({
        pathname: "/dashboard/unities",
        state: enterprise,
      });
    }
  }, [enterprise, history]);

  return (
    <>
      <div>
        <AppBar />
        <Grid>
          <Grid item xs={12}>
            {showNotVerified ? (
              <Card
                showUnityConf={false}
                title={
                  "Status da Empresa: Aguardando avaliação da Microsistemas"
                }
              />
            ) : (
              <Card
                showUnityConf={false}
                title={"Criar nova empresa"}
                handleClick={() =>
                  history.push({
                    pathname: "/dashboard/new/enterprise",
                    state: true,
                  })
                }
                btnTitle="Nova empresa"
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
