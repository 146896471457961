import React, { useState, useEffect } from "react";

import {
  FormControl,
  Input,
  Grid,
  Button,
  CardContent,
  Card,
  InputLabel,
  FormGroup,
  Typography,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation } from "react-router-dom";

import axios from "constants/axios";

import useStyles from "./styles";

import image from "../../../assets/img/background/bg7.jpg";

/* import Footer from "../../../components/Footer";*/

interface Location {
  changes: string;
  alertNowLogin: boolean;
}

export default function Login() {
  const classes = useStyles();
  let [email, setEmail] = useState<string>(""),
    [password, setPassword] = useState<string>(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertLogin, setAlertLogin] = useState({
      alert: false,
      msg: "",
    }),
    [code, setCode] = useState<string>(""),
    [showCode, setShowCode] = useState(false),
    [askNewCode, setAskNewCode] = useState(false),
    [loading, setLoading] = useState<boolean>();
  let history = useHistory(),
    location = useLocation<Location>();

  const verify = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
    if (e) {
      e.preventDefault();
    }
    if (!email || !password) {
      return setAlert({ msg: "Campos faltando", alert: true });
    }
    setLoading(true);
    axios
      .post("/enterprise/user/login", {
        email,
        password,
      })
      .then(({ data }) => {
        setAlertLogin({
          msg: "Foi enviado um código de login para o seu email",
          alert: true,
        });
        setLoading(false);
        setShowCode(true);
      })
      .catch((error) => {
        setAlert({
          msg: "Erro ao entrar: " + error?.response?.data?.error,
          alert: true,
        });
        setLoading(false);
        return console.log(error);
      });
  };

  const checkTwoFactor = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    if (!code) {
      return setAlert({ msg: "Insira o código", alert: true });
    }
    setLoading(true);
    axios
      .post("/enterprise/user/check/twofactor", { email, password, code })
      .then(({ data }) => {
        window.sessionStorage.setItem("@LockytEnterprise:token", data.token);
        if (data.isAdm) {
          window.sessionStorage.setItem("@isAdm", data?.isAdm);
        }
        setLoading(false);
        axios
          .get("/enterprise/list/user/enterprise", {
            headers: {
              Authorization: "bearer " + data.token,
            },
          })
          .then(({ data }) => {
            if (data.enterprise) {
              if (!data.enterprise.verified) {
                return history.push({
                  pathname: "/dashboard/main",
                  state: { verified: false },
                });
              }
              sessionStorage.setItem("@Enterprise:id", data?.enterprise?._id);
              history.push({
                pathname: "/dashboard/unities",
              });
            } else {
              history.push("/dashboard/main");
            }
          })
          .catch((error) => {
            setAlert({
              msg: "Problema ao entrar: " + error?.response?.data?.error,
              alert: true,
            });
            setLoading(false);
            return console.log(error);
          });
      })
      .catch((error) => {
        switch (error.response.status) {
          case 406:
            setAlert({
              msg: "Problema ao entrar: " + error?.response?.data?.error,
              alert: true,
            });
            setAskNewCode(true);
            break;
          default:
            setAlert({
              msg: "Problema ao entrar: " + error?.response?.data?.error,
              alert: true,
            });
            break;
        }
        setLoading(false);
        return console.log(error);
      });
  };

  useEffect(() => {
    if (location?.state?.changes) {
      return setAlertLogin({
        msg: location.state.changes,
        alert: true,
      });
    }
    if (location?.state?.alertNowLogin) {
      setAlertLogin({
        msg: "Enviamos para o seu email uma mensagem de ativação de conta.",
        alert: true,
      });
    }
  }, [location]);

  return (
    <>
      <div
        className={classes.backgroundImage}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div>
          {loading ? <LinearProgress color="secondary" /> : <></>}
          {alert.alert ? (
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlert({ msg: "", alert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle> {alert.msg}</AlertTitle>
            </Alert>
          ) : (
            <></>
          )}

          {alertLogin.alert && (
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlertLogin({ msg: "", alert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle> {alertLogin.msg}</AlertTitle>
            </Alert>
          )}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.logos}
          >
            <Grid item className={classes.gridLogos}>
              <a
                href="https://microsistemas.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../../assets/img/logos/microWhite.png")}
                  alt="Logo microsistemas"
                  className={classes.logoMicro}
                />
              </a>
            </Grid>
            <Grid item className={classes.gridLogos}>
              <a
                href="https://lockyt.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../../assets/img/logos/lockytBranco.png")}
                  alt="Logo lockyt"
                  className={classes.logoLockyt}
                />
              </a>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Card className={classes.root}>
                <CardContent>
                  {showCode ? (
                    <form onSubmit={checkTwoFactor}>
                      <FormGroup>
                        <Typography variant="h6">
                          Verificão por email
                        </Typography>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="email">
                            Código do email
                          </InputLabel>
                          <Input
                            id="code"
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          size="small"
                          className={classes.btn}
                          type="submit"
                        >
                          Próximo
                        </Button>
                        {askNewCode && (
                          <Button
                            size="small"
                            className={classes.btnForgot}
                            onClick={() => {
                              verify(undefined);
                              setAskNewCode(false);
                              setCode("");
                            }}
                          >
                            <Typography variant="body2">
                              Pedir novamente
                            </Typography>
                          </Button>
                        )}
                      </FormGroup>
                    </form>
                  ) : (
                    <form onSubmit={verify}>
                      <FormGroup>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="email">Email </InputLabel>
                          <Input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="password">Senha </InputLabel>
                          <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          size="small"
                          className={classes.btn}
                          type="submit"
                        >
                          Entrar
                        </Button>
                        <div className={classes.btnSecondaryContainer}>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            ou
                          </Typography>
                          <Button
                            size="small"
                            className={classes.btnSecondary}
                            onClick={() => history.push("/register")}
                          >
                            Cadastre-se
                          </Button>
                        </div>
                        <Button
                          size="small"
                          className={classes.btnForgot}
                          onClick={() => history.push("/forgot/password")}
                        >
                          <Typography variant="body2">
                            Esqueceu sua senha?
                          </Typography>
                        </Button>
                      </FormGroup>
                    </form>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        {/*<Footer />*/}
      </div>
    </>
  );
}
