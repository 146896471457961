import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        minWidth: "30ch",
        marginTop: theme.spacing(5),
      },
      marginRight: "1%",
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(3),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%",
      marginTop: theme.spacing(4),
    },
    dropdown: {
      backgroundColor: "transparent",
    },
  })
);
export default useStyles;
