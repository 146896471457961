import React, { useState } from "react";

import {
  Paper,
  TextField,
  Button,
  Typography,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import AppBar from "../../../components/AppBar";
import axios from "../../../constants/axios";
import getToken from "../../../functions/getToken";

import useStyles from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import isEmail from "functions/isEmail";
import isCPF from "functions/isCPF";
import isCNPJ from "functions/isCNPJ";

export default function NewEnterprise() {
  const classes = useStyles(),
    [company_name, setCompany_name] = useState(""),
    [trade_name, setTrade_name] = useState(""),
    [state_registration, setState_registration] = useState(""),
    [email, setEmail] = useState(""),
    [cellphone, setCellphone] = useState(""),
    [associate1Name, setAssociate1Name] = useState(""),
    [associate1Email, setAssociate1Email] = useState(""),
    [associate1Address, setAssociate1Address] = useState(""),
    [associate1Cellphone, setAssociate1Cellphone] = useState(""),
    [associate1Document, setAssociate1Document] = useState(""),
    [associate2Name, setAssociate2Name] = useState(""),
    [associate2Email, setAssociate2Email] = useState(""),
    [associate2Address, setAssociate2Address] = useState(""),
    [associate2Cellphone, setAssociate2Cellphone] = useState(""),
    [associate2Document, setAssociate2Document] = useState(""),
    [legalName, setLegalName] = useState(""),
    [legalEmail, setLegalEmail] = useState(""),
    [legalAddress, setLegalAddress] = useState(""),
    [legalCellphone, setLegalCellphone] = useState(""),
    [legalDocument, setLegalDocument] = useState(""),
    [contactName, setContactName] = useState(""),
    [contactCellphone, setContactCellphone] = useState(""),
    [contactDocument, setContactDocument] = useState(""),
    [contactEmail, setContactEmail] = useState(""),
    [contactAddress, setContactAddress] = useState(""),
    [address, setAddress] = useState(""),
    [document, setDocument] = useState(""),
    [loading, setLoading] = useState<boolean>(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    location = useLocation(),
    history = useHistory();

  const checkEmails = () => {
    if (!isEmail(email)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Email da empresa incorreto",
        alert: true,
      });
    }
    if (!isEmail(contactEmail)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Email da pessoa de contato incorreto",
        alert: true,
      });
    }
    if (!isEmail(associate1Email)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Email do sócio 1 incorreto",
        alert: true,
      });
    }
    if (associate2Email && !isEmail(associate2Email)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Email do sócio 2 incorreto",
        alert: true,
      });
    }
    if (!isEmail(legalEmail)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Email do representante legal incorreto",
        alert: true,
      });
    }
    return true;
  };

  const checkCellphone = () => {
    if (cellphone.length !== 11) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Telefone da empresa incorreto",
        alert: true,
      });
    }
    if (associate1Cellphone.length !== 11) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Telefone do sócio 1 incorreto",
        alert: true,
      });
    }
    if (associate2Cellphone && associate2Cellphone.length !== 11) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Telefone do sócio 2 incorreto",
        alert: true,
      });
    }
    if (legalCellphone.length !== 11) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Telefone do representante legal incorreto",
        alert: true,
      });
    }
    if (contactCellphone.length !== 11) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Telefone da pessoa de contato incorreto",
        alert: true,
      });
    }

    return true;
  };

  const checkCPFs = () => {
    if (!isCPF(associate1Document)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "CPF do sócio 1 incorreto",
        alert: true,
      });
    }
    if (!isCPF(legalDocument)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "CPF do representante legal incorreto",
        alert: true,
      });
    }
    if (!isCPF(contactDocument)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "CPF da pessoa de contato incorreto",
        alert: true,
      });
    }

    return true;
  };

  const send = async () => {
    if (
      !company_name ||
      !address ||
      !document ||
      !trade_name ||
      !email ||
      !cellphone ||
      !legalName ||
      !legalCellphone ||
      !legalEmail ||
      !legalAddress ||
      !contactName ||
      !contactEmail ||
      !contactAddress ||
      !contactCellphone ||
      !associate1Address ||
      !associate1Cellphone ||
      !associate1Email ||
      !associate1Email
    ) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Preencha os campos com *",
        alert: true,
      });
    }

    if (!isCNPJ(document)) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "CNPJ da empresa incorreto",
        alert: true,
      });
    }

    if (!checkEmails()) {
      return;
    }
    if (!checkCellphone()) {
      return;
    }
    if (!checkCPFs()) {
      return;
    }

    let associate2: object | undefined = undefined;
    if (
      associate2Email &&
      associate2Cellphone &&
      associate2Cellphone &&
      associate2Document
    ) {
      associate2 = {
        name: associate2Name,
        cellphone: associate2Cellphone,
        document: isCPF(associate2Document),
        email: associate2Email,
      };
    }

    setLoading(true);
    axios
      .post(
        "/enterprise/add/enterprise",
        {
          company_name,
          trade_name,
          state_registration,
          document: isCNPJ(document),
          email,
          cellphone: "+55" + cellphone,
          address,
          legal_representative: {
            name: legalName,
            cellphone: "+55" + legalCellphone,
            document: isCPF(legalDocument),
            email: legalEmail,
            address: legalAddress,
          },
          contact_person: {
            name: contactName,
            cellphone: "+55" + contactCellphone,
            document: isCPF(contactDocument),
            email: contactEmail,
            address: contactAddress,
          },
          associates: [
            {
              name: associate1Name,
              cellphone: "+55" + associate1Cellphone,
              document: isCPF(associate1Document),
              email: associate1Email,
            },
            associate2,
          ],
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/dashboard/main",
          state: { verified: false },
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.data.code === 11000) {
          return setAlert({
            msg: "Erro ao salvar: CPNJ já cadastrado",
            alert: true,
          });
        }
        if (error?.response?.data?.error) {
          setAlert({
            msg: "Erro ao salvar: " + error?.response?.data?.error,
            alert: true,
          });
        } else {
          setAlert({
            msg: "Erro ao salvar",
            alert: true,
          });
        }
      });
  };

  return (
    <>
      <AppBar
        hideDropdown={true}
        goTo={location.state ? undefined : "/dashboard/unities"}
        pageName="Nova Empresa"
      />
      {loading && <LinearProgress color="secondary" />}
      {alert.alert && (
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setAlert({ msg: "", alert: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle> {alert.msg}</AlertTitle>
        </Alert>
      )}
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h4" component="p" color="textPrimary">
          Cadastrar nova empresa
        </Typography>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            required
            id="name"
            label="Razão social"
            value={company_name}
            onChange={(e) => setCompany_name(e.target.value)}
          />
          <TextField
            required
            id="name"
            label="Nome fantasia"
            value={trade_name}
            onChange={(e) => setTrade_name(e.target.value)}
          />
          <TextField
            required
            id="document"
            label="CNPJ "
            value={document}
            onChange={(e) => setDocument(e.target.value)}
          />
          <TextField
            label="Inscrição estadual"
            value={state_registration}
            onChange={(e) => setState_registration(e.target.value)}
          />
          <br></br>
          <br></br>
          <Typography variant="h6" className={classes.subtitle}>
            Endereçamento
          </Typography>
          <TextField
            required
            id="email"
            label="Email da empresa"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            id="cellphone"
            label="Celular da empresa (DDD + número)"
            value={cellphone}
            onChange={(e) => setCellphone(e.target.value)}
          />
          <TextField
            required
            id="address"
            label="Endereço da sede da empresa"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Typography variant="h6" className={classes.subtitle}>
            Sócio 1
          </Typography>
          <TextField
            required
            label="Nome"
            value={associate1Name}
            onChange={(e) => setAssociate1Name(e.target.value)}
          />
          <TextField
            required
            label="Email"
            value={associate1Email}
            onChange={(e) => setAssociate1Email(e.target.value)}
          />
          <TextField
            required
            label="Celular (DDD + número)"
            value={associate1Cellphone}
            onChange={(e) => setAssociate1Cellphone(e.target.value)}
          />
          <TextField
            required
            label="CPF"
            value={associate1Document}
            onChange={(e) => setAssociate1Document(e.target.value)}
          />
          <TextField
            required
            label="Endereço"
            value={associate1Address}
            onChange={(e) => setAssociate1Address(e.target.value)}
          />
          <Typography variant="h6" className={classes.subtitle}>
            Sócio 2
          </Typography>
          <TextField
            label="Nome"
            value={associate2Name}
            onChange={(e) => setAssociate2Name(e.target.value)}
          />
          <TextField
            label="Email"
            value={associate2Email}
            onChange={(e) => setAssociate2Email(e.target.value)}
          />
          <TextField
            label="Celular (DDD + número)"
            value={associate2Cellphone}
            onChange={(e) => setAssociate2Cellphone(e.target.value)}
          />
          <TextField
            label="CPF"
            value={associate2Document}
            onChange={(e) => setAssociate2Document(e.target.value)}
          />
          <TextField
            label="Endereço"
            value={associate2Address}
            onChange={(e) => setAssociate2Address(e.target.value)}
          />
          <br></br>
          <br></br>
          <Typography variant="h6" className={classes.subtitle}>
            Representante Legal
          </Typography>
          <TextField
            required
            label="Nome"
            value={legalName}
            onChange={(e) => setLegalName(e.target.value)}
          />
          <TextField
            required
            label="Email"
            value={legalEmail}
            onChange={(e) => setLegalEmail(e.target.value)}
          />
          <TextField
            required
            label="Celular (DDD + número)"
            value={legalCellphone}
            onChange={(e) => setLegalCellphone(e.target.value)}
          />
          <TextField
            required
            label="CPF"
            value={legalDocument}
            onChange={(e) => setLegalDocument(e.target.value)}
          />
          <TextField
            required
            label="Endereço"
            value={legalAddress}
            onChange={(e) => setLegalAddress(e.target.value)}
          />
          <br></br>
          <br></br>
          <Typography variant="h6" className={classes.subtitle}>
            Pessoa para contato
          </Typography>
          <TextField
            required
            label="Nome"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
          <TextField
            required
            label="Email"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
          <TextField
            required
            label="Celular (DDD + número)"
            value={contactCellphone}
            onChange={(e) => setContactCellphone(e.target.value)}
          />
          <TextField
            required
            label="CPF"
            value={contactDocument}
            onChange={(e) => setContactDocument(e.target.value)}
          />
          <TextField
            required
            label="Endereço"
            value={contactAddress}
            onChange={(e) => setContactAddress(e.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={send}
          >
            Salvar
          </Button>
        </form>
      </Paper>
    </>
  );
}
