import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#00000077",
      height: "3.1rem",
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
    logoLockyt: {
      width: "6.5rem",
      marginTop: "5px",
    },
    grid: {},
    wrapper: {
      height: "100%",
      width: "100%",
      margin: 0,
      padding: 0,
      border: 0,
    },
    wrapperTd: {
      verticalAlign: "middle",
      textAlign: "center",
    },
  })
);

export default useStyles;
