import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    gridContainer: {
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
      width: window.innerWidth * 0.98,
    },
  })
);

export default useStyles;
