import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";

import Login from "./views/Auth/Login";
import ResetPwd from "./views/Auth/ResetPwd";
import ForgotPwd from "./views/Auth/ForgotPwd";
import Activate from "./views/Auth/Activate";
import Register from "./views/Auth/Register";

import Home from "./views/Main/Home";
import Unities from "./views/Main/Unities";
import NewEnterprise from "./views/Main/NewEnterprise";
import NewUnity from "./views/Main/NewUnity";
import DevOpts from "./views/Main/DevOpts";
import UnityOpts from "./views/Main/UnityOpts";
import UnityDeliveries from "./views/Main/UnityDeliveries";
import EnterpriseConfig from "./views/Main/EnterpriseConfig";
import EnterpriseReport from "./views/Main/EnterpriseReport";
import UnityConfig from "./views/Main/UnityConfig";

import isAuthenticated from "./functions/isAuthenticated";

import mainTheme from "./theme";
import "assets/css/main.css";

import * as serviceWorker from "./serviceWorker";

const hist = createBrowserHistory();

interface IProps {
  [key: string]: any;
}

function PrivateRoute({ children, ...rest }: IProps) {
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated() ? children : <Redirect to="/login" />)}
    />
  );
}

ReactDOM.render(
  <ThemeProvider theme={mainTheme}>
    <Router history={hist}>
      <Switch>
        <PrivateRoute path="/dashboard/main">
          <Route exact component={Home} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/unities">
          <Route exact component={Unities} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/new/enterprise">
          <Route exact component={NewEnterprise} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/new/unity">
          <Route exact component={NewUnity} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/unity/dev">
          <Route exact component={DevOpts} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/unity/options">
          <Route exact component={UnityOpts} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/unity/deliveries">
          <Route exact component={UnityDeliveries} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/unity/config">
          <Route exact component={UnityConfig} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/config/enterprise">
          <Route exact component={EnterpriseConfig} />
        </PrivateRoute>
        <PrivateRoute path="/dashboard/report/enterprise">
          <Route exact component={EnterpriseReport} />
        </PrivateRoute>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot/password" component={ForgotPwd} />
        <Route exact path="/reset/password/:token" component={ResetPwd} />
        <Route exact path="/activate/:token" component={Activate} />
        <Route exact path="/register" component={Register} />
        <Redirect to="/login" />
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);

serviceWorker.register();
