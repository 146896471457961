import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      background: `linear-gradient(to right, ${theme.palette.primary.main},#008ec9aa 40%, #0c2c7b99, ${theme.palette.secondary.main})`,
    },
    menuButton: {
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: "#00000011",
        boxShadow: `0px 0px 8px 0.5px ${theme.palette.action.hover}`,
      },
    },
    titleContainer: {
      flexGrow: 1,
    },
    title: {
      color: "white",
    },
    newUnity: {
      marginLeft: 10,
    },
    btnNewUnity: {
      marginRight: 10,
      backgroundColor: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: `0px 0px 5px 1px ${theme.palette.action.hover}`,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    dropdown: {
      color: "white",
      backgroundColor: "transparent",
    },
    arrowHeader: {
      flexDirection: "row",
      width: "20%",
    },
    pageName: {
      marginLeft: "10%",
    },
    backBtn: {
      color: "white",
    },
    menuItem: {
      "&:hover": {
        color: theme.palette.primary.contrastText,
      },
    },
  })
);

export default useStyles;
