import React, { useState, useEffect } from "react";

import {
  Paper,
  TextField,
  Button,
  Typography,
  LinearProgress,
  IconButton,
  FormControl,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import AppBar from "../../../components/AppBar";
import axios from "../../../constants/axios";
import getToken from "../../../functions/getToken";

import useStyles from "./styles";
import { useHistory } from "react-router-dom";

interface Enterprise {
  name: string;
}

export default function NewUnity() {
  const classes = useStyles(),
    [name, setName] = useState(""),
    [info, setInfo] = useState(""),
    [address, setAddress] = useState(""),
    [enterprise, setEnterprise] = useState<Enterprise>(),
    [loading, setLoading] = useState<boolean>(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    history = useHistory();

  const send = async () => {
    if (!name || !info || !address) {
      return setAlert({
        msg: "Por favor preencha os campos",
        alert: true,
      });
    }

    setLoading(true);
    axios
      .post(
        "/enterprise/add/unity",
        {
          name,
          info,
          address,
          enterprise: enterprise,
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        history.push({
          pathname: "/dashboard/unities",
          state: enterprise,
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.error) {
          setAlert({
            msg: "Erro ao salvar: " + error?.response?.data?.error,
            alert: true,
          });
        } else {
          setAlert({
            msg: "Erro ao salvar",
            alert: true,
          });
        }
      });
  };

  const getData = async () => {
    axios
      .get("/enterprise/list/user/enterprise", {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        setEnterprise(data.enterprise);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AppBar
        hideDropdown={true}
        goTo="/dashboard/unities"
        pageName="Nova Unidade"
      />
      {loading && <LinearProgress color="secondary" />}
      {alert.alert && (
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setAlert({ msg: "", alert: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle> {alert.msg}</AlertTitle>
        </Alert>
      )}
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h4" component="p" color="textSecondary">
          Cadastrar nova unidade administrativa
        </Typography>
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl className={classes.formControl}>
            <Typography variant="h6">
              Empresa responsável: {enterprise?.name}
            </Typography>
          </FormControl>
          <TextField
            required
            id="name"
            label="Nome da unidade"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            required
            id="info"
            label="Descrição"
            value={info}
            onChange={(e) => setInfo(e.target.value)}
            fullWidth
          />
          <TextField
            required
            id="address"
            label="Endereço da unidade"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={send}
          >
            Salvar
          </Button>
        </form>
      </Paper>
    </>
  );
}
