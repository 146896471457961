import React, { useState, useEffect } from "react";

import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Theme,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

import AppBar from "../../../components/AppBar";

import useStyles from "./styles";
import { useLocation, useHistory } from "react-router-dom";

interface Data {
  countOpenings: number;
  plan: {
    openings: number;
  };
}

interface Location {
  enterprise: Data;
}

export default function NewEnterprise() {
  const classes = useStyles(),
    [data, setData] = useState<Data>({
      countOpenings: 0,
      plan: { openings: 0 },
    }),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [progressClass, setProgressClass] = useState(""),
    history = useHistory(),
    location = useLocation<Location>();

  useEffect(() => {
    if (location?.state?.enterprise?.plan) {
      setData(location.state.enterprise);
    } else {
      return history.push("/dashboard/unities");
    }
  }, [location, history]);

  useEffect(() => {
    if (
      data &&
      data?.countOpenings / data?.plan?.openings >= 0.5 &&
      data?.countOpenings / data?.plan?.openings < 0.75
    ) {
      setProgressClass(classes.warning);
    }
    if (data && data?.countOpenings / data?.plan?.openings >= 0.75) {
      setProgressClass(classes.danger);
    }
  }, [setProgressClass, classes.warning, classes.danger, data]);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <>
      <AppBar
        hideDropdown={true}
        goTo="/dashboard/unities"
        pageName="Relatório"
      />
      {alert.alert && (
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setAlert({ msg: "", alert: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle> {alert.msg}</AlertTitle>
        </Alert>
      )}
      <Paper elevation={3} className={classes.paper}>
        <Typography
          variant="h4"
          component="p"
          color="textPrimary"
          align="center"
        >
          Relatório da empresa
        </Typography>

        <div className={classes.progressContainer}>
          <LinearProgress
            variant="determinate"
            value={(data?.countOpenings / data?.plan?.openings) * 100 || 0}
            color="secondary"
            className={progressClass}
            style={{ width: "50%" }}
          />
        </div>
        <Typography color="inherit" align="center">
          Quantidade de entregas: {data?.countOpenings || ""} -{" "}
          {((data?.countOpenings / data?.plan?.openings) * 100).toFixed(1) || 0}
          %
        </Typography>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.paperContent}
        >
          <Grid item>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit" align="center">
                    Para alterar o limite de entregas, entre em contato com a
                    Microsistemas
                  </Typography>
                </React.Fragment>
              }
            >
              <IconButton>
                <InfoIcon color="secondary" />
              </IconButton>
            </HtmlTooltip>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
