import React, { useState } from "react";

import {
  FormControl,
  Input,
  Grid,
  Button,
  CardContent,
  Card,
  InputLabel,
  FormGroup,
  Typography,
  IconButton,
  LinearProgress,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import isEmail from "functions/isEmail";

import axios from "constants/axios";

import useStyles from "./styles";

import image from "../../../assets/img/background/bg7.jpg";

export default function Register() {
  const classes = useStyles();
  let [email, setEmail] = useState<string>(""),
    [password, setPassword] = useState<string>(""),
    [confPassword, setConfPassword] = useState<string>(""),
    [name, setName] = useState<string>(""),
    [cellphone, setCellphone] = useState<string>(""),
    [document, setDocument] = useState<string>(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [loading, setLoading] = useState<boolean>(),
    [checked, setChecked] = useState(false);
  let history = useHistory();

  const validaCPF = async (cpf: string) => {
    let add, rev;
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf === "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      return false;
    // Valida 1o digito
    add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return cpf;
  };

  const verify = async () => {
    if (
      !document ||
      !name ||
      !password ||
      !cellphone ||
      !email ||
      !confPassword
    ) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Campos faltando", alert: true });
    }

    if (!checked) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Para continuar aceite os termos de uso e políticas de privacidade",
        alert: true,
      });
    }

    if (!(await validaCPF(document))) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "CPF inválido", alert: true });
    }

    if (!isEmail(email)) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Email inválido", alert: true });
    }

    let realCell = cellphone.replace(/\D/g, "");

    if (realCell.length < 11 || realCell.length > 11) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Número de telefone incorreto", alert: true });
    }

    if (password !== confPassword) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "As senhas não são iguais", alert: true });
    }

    setLoading(!loading);
    axios
      .post("/enterprise/user/register", {
        email,
        name,
        document: await validaCPF(document),
        cellphone: "+55" + realCell,
        password,
      })
      .then(() => {
        history.push({
          pathname: "/login",
          state: {
            alertNowLogin: true,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setAlert({
          msg: "Erro ao cadastrar: " + error?.response?.data?.error,
          alert: true,
        });
        setLoading(false);
        return console.log(error);
      });
  };

  const maskCel = async (e: any) => {
    let v = e.target.value;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    setCellphone(v);
  };

  return (
    <>
      <div
        className={classes.backgroundImage}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div>
          {loading ? <LinearProgress color="secondary" /> : <></>}
          {alert.alert ? (
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlert({ msg: "", alert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle> {alert.msg}</AlertTitle>
            </Alert>
          ) : (
            <></>
          )}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.logos}
          >
            <Grid item className={classes.gridLogos}>
              <a
                href="https://microsistemas.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../../assets/img/logos/microWhite.png")}
                  alt="Logo microsistemas"
                  className={classes.logoMicro}
                />
              </a>
            </Grid>
            <Grid item className={classes.gridLogos}>
              <a
                href="https://lockyt.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../../assets/img/logos/lockytBranco.png")}
                  alt="Logo lockyt"
                  className={classes.logoLockyt}
                />
              </a>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Card className={classes.root}>
                <CardContent>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel>Nome completo </InputLabel>
                      <Input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel>CPF </InputLabel>
                      <Input
                        id="cpf"
                        value={document}
                        onChange={(e) => setDocument(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel>Telefone</InputLabel>
                      <Input
                        id="cellphone"
                        value={cellphone}
                        onChange={(e) => maskCel(e)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="email">Email </InputLabel>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="password">Senha </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="confPassword">
                        Confirme sua senha
                      </InputLabel>
                      <Input
                        id="confPassword"
                        type="password"
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControlLabel
                      style={{ textAlign: "center" }}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                          value="checkedA"
                        />
                      }
                      label={
                        <div>
                          <p>
                            Aceito os{" "}
                            <a
                              href="https://s3.us-east-2.amazonaws.com/lockyt.files/termos/termos_de_uso.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              termos de uso
                            </a>{" "}
                            e{" "}
                            <a
                              href="https://s3.us-east-2.amazonaws.com/lockyt.files/termos/politica_de_privacidade.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              políticas de privacidade
                            </a>
                          </p>
                        </div>
                      }
                    />
                    <Button
                      size="small"
                      className={classes.btn}
                      onClick={verify}
                    >
                      Cadastre-se
                    </Button>
                    <div className={classes.btnSecondaryContainer}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        ou
                      </Typography>
                      <Button
                        size="small"
                        className={classes.btnSecondary}
                        onClick={() => history.push("/login")}
                      >
                        Entrar
                      </Button>
                    </div>
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
