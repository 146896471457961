import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    text: {
      fontSize: 14,
      marginTop: 10,
    },
    pos: {
      marginBottom: 12,
    },
    btns: {
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.primary.contrastText,
      },
    },
  })
);

export default useStyles;
