import React, { useState, useEffect } from "react";

import {
  Paper,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import moment from "moment";

import AppBar from "../../../components/AppBar";
import * as unityId from "../../../functions/unityId";
import axios from "constants/axios";
import getToken from "functions/getToken";

import useStyles from "./styles";
import { useLocation } from "react-router-dom";

interface Data {
  info: string;
  name: string;
  _id: string;
  address: string;
  createdAt: string;
}

export default function UnityConfig() {
  const classes = useStyles(),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    location = useLocation<Data>(),
    [name, setName] = useState(() => {
      if (location?.state?.name) {
        return location?.state?.name;
      } else {
        return "";
      }
    }),
    [info, setInfo] = useState(() => {
      if (location?.state?.info) {
        return location?.state?.info;
      } else {
        return "";
      }
    }),
    [address, setAddress] = useState(() => {
      if (location?.state?.address) {
        return location?.state?.address;
      } else {
        return "";
      }
    });
  let history = useHistory();

  const alterValues = async () => {
    axios
      .put(
        "/enterprise/unity/alter/data",
        {
          name,
          info,
          address,
          unityId: unityId.getUnityId(),
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        window.scrollTo(0, 0);
        setAlertSuccess({ alert: true, msg: "Salvo com sucesso" });
      })
      .catch((error) => {
        let { status } = error?.response;
        window.scrollTo(0, 0);
        switch (status) {
          case 401:
            return setAlert({ alert: true, msg: "Não autorizado" });
          default:
            return setAlert({ alert: true, msg: "Problema ao atualizar" });
        }
      });
  };

  useEffect(() => {
    if (
      !location?.state?.address ||
      !location?.state?.info ||
      !location?.state?.name
    ) {
      return history.push({
        pathname: "/dashboard/unities",
      });
    }
  }, [location, history]);

  return (
    <>
      <AppBar
        hideDropdown={true}
        goTo="/dashboard/unities"
        pageName="Configurações da unidade"
      />
      {alertSuccess.alert && (
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setAlertSuccess({ msg: "", alert: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle> {alertSuccess.msg}</AlertTitle>
        </Alert>
      )}
      {alert.alert && (
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setAlert({ msg: "", alert: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle> {alert.msg}</AlertTitle>
        </Alert>
      )}
      <Paper elevation={3} className={classes.paper}>
        <Typography
          variant="h4"
          component="p"
          color="textPrimary"
          align="center"
        >
          Informações da Unidade
        </Typography>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={4}
          className={classes.paperContent}
        >
          <Grid item className={classes.inputItem}>
            <TextField
              required
              label="Nome da unidade"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item className={classes.inputItem}>
            <TextField
              required
              label="Descrição"
              value={info}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInfo(e.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item className={classes.inputItem}>
            <TextField
              required
              label="Endereço"
              value={address}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAddress(e.target.value)
              }
              fullWidth
            />
          </Grid>

          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Data de cadastro:{" "}
              <b>{moment(location.state?.createdAt).format("DD/MM/YYYY")}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => alterValues()}
            >
              Salvar alterações
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
