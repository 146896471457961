import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  LinearProgress,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
import MaterialTable from "material-table";

import AppBar from "components/AppBar";

import axios from "constants/axios";

import getToken from "functions/getToken";
import getAdmStatus from "functions/getAdmStatus";
import isEmail from "functions/isEmail";
import * as unityId from "functions/unityId";

import useStyles from "./styles";

interface State {
  _id: string;
  name: string;
}

interface DeliveryMan {
  address: string;
  name: string;
  cellphone: string;
  document: string;
  email: string;
  _id: string;
}

interface People {
  name: string;
  email: string;
  office: 1 | 2;
}

export default function Home() {
  const classes = useStyles();
  let [deliveryMen, setDeliveryMen] = useState([]),
    [people, setPeople] = useState([]),
    [loading, setLoading] = useState<boolean>(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    [deliveryManEmail, setDeliveryManEmail] = useState<string>();

  let lookupOffice = {
    1: "Gerente",
    2: "Supervidor",
  };

  const validaCPF = async (cpf: string) => {
    let add, rev;
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf === "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      return false;
    // Valida 1o digito
    add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return cpf;
  };

  const formataCEL = (numero: string): string => {
    let length = numero.length,
      numeros = numero.split(""),
      telefoneFormatado;
    if (length === 13) {
      telefoneFormatado =
        "(" +
        numeros[3] +
        numeros[4] +
        ") " +
        numero.substring(5, 9) +
        "-" +
        numero.substring(9, 13);
    } else if (length === 14) {
      telefoneFormatado =
        "(" +
        numeros[3] +
        numeros[4] +
        ") " +
        numero.substring(5, 10) +
        "-" +
        numero.substring(10, 14);
    } else {
      telefoneFormatado = "(**) *****-****";
    }
    return telefoneFormatado;
  };

  const getDeliveryMen = useCallback(async () => {
    axios
      .get("/enterprise/list/unity/deliveryMen/" + unityId.getUnityId(), {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        let result: any = [];
        data.deliveryMen.forEach((element: DeliveryMan) => {
          result.push({
            address: element.address,
            name: element.name,
            cellphone: formataCEL(element.cellphone),
            document: element.document,
            email: element.email,
            _id: element._id,
          });
        });
        setDeliveryMen(result);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error.response.status) {
          case 401:
            return setAlert({ alert: true, msg: "Não autorizado" });
          default:
            return setAlert({ alert: true, msg: "Problema ao carregar" });
        }
      });
  }, []);

  const addDeliveryMan = async (data: DeliveryMan) => {
    let { email, document, address, name, cellphone } = data;
    if (!document || !email || !address || !name || !cellphone) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "Preencha todos os campos" });
    }

    if (!isEmail(email)) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Email inválido", alert: true });
    }

    if (cellphone.length < 11 || cellphone.length > 11) {
      window.scrollTo(0, 0);
      return setAlert({
        msg: "Número de dígitos do telefone incorreto",
        alert: true,
      });
    }
    let cpf = await validaCPF(document);
    if (!cpf) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "CPF inválido" });
    }
    setLoading(true);
    axios
      .post(
        "/enterprise/deliveryman/register",
        {
          email,
          document: cpf,
          address,
          name,
          cellphone: "+55" + cellphone.replace(/([^0-9])+/g, ""),
          unity: unityId.getUnityId(),
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        setAlertSuccess({ alert: true, msg: "Cadastrado com sucesso" });
        getDeliveryMen();
        window.scrollTo(0, 0);
        setLoading(false);
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setAlert({
          alert: true,
          msg: `Erro ao cadastrar: ${error?.response?.data?.error}`,
        });
        setLoading(false);
      });
  };

  const editDeliveryMan = async (newData: DeliveryMan) => {
    let { email, document, address, name, cellphone } = newData;
    if (!document || !email || !address || !name || !cellphone) {
      return setAlert({ alert: true, msg: "Preencha todos os campos" });
    }
    if (cellphone.length < 11) {
      return setAlert({
        alert: true,
        msg: "O telefone deve conter DDD + número",
      });
    }
    let cpf = await validaCPF(document);
    if (!cpf) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "CPF inválido" });
    }
    setLoading(true);
    axios
      .put(
        "/enterprise/deliveryman/alter/data",
        {
          email,
          document: cpf,
          address,
          name,
          cellphone: "+55" + cellphone.replace(/([^0-9])+/g, ""),
          unityId: unityId.getUnityId(),
          deliveryManId: newData._id,
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        setAlertSuccess({ alert: true, msg: "Cadastrado com sucesso" });
        getDeliveryMen();
        window.scrollTo(0, 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error?.response?.data?.error);
        window.scrollTo(0, 0);
        setAlert({
          alert: true,
          msg: `Erro ao editar: ${error?.response?.data?.error}`,
        });
        setLoading(false);
      });
  };

  const removeDeliveryMan = async (oldData: DeliveryMan) => {
    setLoading(true);
    axios
      .delete(
        "/enterprise/deliveryman/delete/" +
          oldData._id +
          "/" +
          unityId.getUnityId(),
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        setAlertSuccess({ alert: true, msg: "Removido com sucesso" });
        getDeliveryMen();
        window.scrollTo(0, 0);
        setLoading(false);
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        console.log(error?.response?.data?.error);
        setAlert({
          alert: true,
          msg: `Erro ao apagar: ${error?.response?.data?.error}`,
        });
        setLoading(false);
      });
  };

  /* Functions related to PEOPLE */

  const getPeople = useCallback(async () => {
    setLoading(true);
    axios
      .get("/enterprise/list/unity/people/" + unityId.getUnityId(), {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        let result: any = [];
        data.adms.forEach((element: People) => {
          result.push({
            name: element.name,
            email: element.email,
            office: 1,
          });
        });
        data.maintainers.forEach((element: People) => {
          result.push({
            name: element.name,
            email: element.email,
            office: 2,
          });
        });
        setPeople(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const addPerson = async (newData: People) => {
    let { email, office } = newData;
    if (!isEmail(email)) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Email inválido", alert: true });
    }
    setLoading(true);
    axios
      .post(
        "/enterprise/add/unity/people",
        {
          unityId: unityId.getUnityId(),
          email,
          office,
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        setLoading(false);
        getPeople();
        window.scrollTo(0, 0);
        setAlertSuccess({ alert: true, msg: "Adicionado com sucesso" });
      })
      .catch((error) => {
        setLoading(false);
        window.scrollTo(0, 0);
        setAlert({
          alert: true,
          msg: `Erro ao cadastrar: ${error?.response?.data?.error}`,
        });
        console.log(error);
      });
  };

  const deletePeople = async (oldData: People) => {
    let { email, office } = oldData;
    setLoading(true);
    axios
      .delete(
        "/enterprise/delete/unity/people/" +
          email +
          "/" +
          office +
          "/" +
          unityId.getUnityId(),
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        window.scrollTo(0, 0);
        setLoading(false);
        getPeople();
        setAlertSuccess({ alert: true, msg: "Removido com sucesso" });
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setLoading(false);
        switch (error.response.status) {
          case 401:
            return setAlert({ alert: true, msg: "Não autorizado" });
          case 403:
            return setAlert({ alert: true, msg: "Não é possível se excluir" });
          default:
            return setAlert({ alert: true, msg: "Erro ao remover" });
        }
      });
  };

  useEffect(() => {
    getDeliveryMen();
    if (getAdmStatus()) {
      getPeople();
    }
  }, [getDeliveryMen, getPeople]);

  const newDeliveryManPwd = async () => {
    setLoading(true);
    axios
      .post(
        "/enterprise/deliveryman/new/temp/password",
        {
          email: deliveryManEmail,
          unityId: unityId.getUnityId(),
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then((val) => {
        window.scrollTo(0, 0);
        setLoading(false);
        getPeople();
        setAlertSuccess({ alert: true, msg: "Solicitado com sucesso" });
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        let { status } = error.response;
        setLoading(false);
        console.log(error);
        switch (status) {
          case 401:
            return setAlert({ alert: true, msg: "Não autorizado" });
          case 404:
            return setAlert({ alert: true, msg: "Entregador não encontrado" });
          default:
            return setAlert({ alert: true, msg: "Erro ao pedir nova senha" });
        }
      });
  };

  return (
    <>
      <div>
        <AppBar
          hideDropdown={true}
          goTo="/dashboard/unities"
          pageName="Detalhes"
        />
        {loading && <LinearProgress color="secondary" />}
        {alert.alert && (
          <Alert
            variant="filled"
            severity="error"
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setAlert({ msg: "", alert: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle> {alert.msg}</AlertTitle>
          </Alert>
        )}
        {alertSuccess.alert && (
          <Alert
            variant="filled"
            severity="success"
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setAlertSuccess({ msg: "", alert: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle> {alertSuccess.msg}</AlertTitle>
          </Alert>
        )}
        <Grid>
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.paper}>
              {getAdmStatus() ? (
                <>
                  <Typography variant="h4">
                    Associados
                  </Typography>
                  <div className={classes.paperDiv}>
                    <MaterialTable
                      options={{ exportButton: true }}
                      columns={[
                        { title: "Nome", field: "name", editable: "never" },
                        { title: "Email", field: "email", editable: "onAdd" },
                        {
                          title: "Cargo",
                          field: "office",
                          lookup: lookupOffice,
                        },
                      ]}
                      data={people}
                      localization={{
                        header: {
                          actions: "Ações",
                        },
                        pagination: {
                          labelDisplayedRows: "{from}-{to} de {count}",
                          labelRowsSelect: "registros",
                          labelRowsPerPage: "Registros por página:",
                          firstAriaLabel: "Primeira",
                          firstTooltip: "Primeira",
                          previousAriaLabel: "Anterior",
                          previousTooltip: "Anterior",
                          nextAriaLabel: "Próxima",
                          nextTooltip: "Próxima",
                          lastAriaLabel: "Última",
                          lastTooltip: "Última",
                        },
                        toolbar: {
                          exportTitle: "Exportar",
                          exportAriaLabel: "Exportar",
                          exportName: "Exportar CSV",
                          searchTooltip: "Pesquisar",
                          searchPlaceholder: "Pesquisar",
                        },
                        body: {
                          emptyDataSourceMessage: "Nimguém na equipe",
                          addTooltip: "Adicionar pessoa",
                          deleteTooltip: "Remover pessoa",
                          editRow: {
                            deleteText: "Remover pessoa?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Confirmar",
                          },
                        },
                      }}
                      title="Equipe"
                      editable={{
                        onRowDelete: deletePeople,
                        onRowAdd: addPerson,
                        //onRowUpdate: () => null,
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className={classes.paperDiv}>
                <MaterialTable
                  options={{ exportButton: true }}
                  columns={[
                    { title: "Nome", field: "name" },
                    { title: "Email", field: "email", editable: "onAdd" },
                    {
                      title: "CPF",
                      field: "document",
                      editable: "onAdd",
                    },
                    { title: "Endereço", field: "address" },
                    { title: "Celular", field: "cellphone" },
                    { title: "id", field: "_id", hidden: true },
                  ]}
                  data={deliveryMen}
                  localization={{
                    header: {
                      actions: "Ações",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "registros",
                      labelRowsPerPage: "Registros por página:",
                      firstAriaLabel: "Primeira",
                      firstTooltip: "Primeira",
                      previousAriaLabel: "Anterior",
                      previousTooltip: "Anterior",
                      nextAriaLabel: "Próxima",
                      nextTooltip: "Próxima",
                      lastAriaLabel: "Última",
                      lastTooltip: "Última",
                    },
                    toolbar: {
                      exportTitle: "Exportar",
                      exportAriaLabel: "Exportar",
                      exportName: "Exportar CSV",
                      searchTooltip: "Pesquisar",
                      searchPlaceholder: "Pesquisar",
                    },
                    body: {
                      emptyDataSourceMessage: "Sem entregadores",
                      addTooltip: "Adicionar entregador",
                      deleteTooltip: "Remover entregador",
                      editTooltip: "Editar entregador",
                      editRow: {
                        deleteText: "Remover entregador?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Confirmar",
                      },
                    },
                  }}
                  title="Entregadores"
                  editable={{
                    onRowDelete: removeDeliveryMan,
                    onRowAdd: addDeliveryMan,
                    onRowUpdate: editDeliveryMan,
                  }}
                />
                <a
                  href="https://s3.us-east-2.amazonaws.com/lockyt.files/apks/delivery/LockytDelivery.apk"
                  download
                  target="nooper"
                  className={classes.btnDownloadAnchor}
                >
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    className={classes.btnDownload}
                  >
                    Download app entregador
                  </Button>
                </a>
              </div>
              <Card className={classes.paperDiv}>
                <CardHeader title="Nova senha de entregador" />
                <CardContent className={classes.inputsContainer}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        id="email"
                        className={classes.input}
                        type="email"
                        label="Email do entregador"
                        value={deliveryManEmail}
                        onChange={(e) => setDeliveryManEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        className={classes.btn}
                        color="secondary"
                        variant="contained"
                        onClick={newDeliveryManPwd}
                      >
                        Pedir nova senha
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
