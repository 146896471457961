import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
    },
    paperDiv: {
      margin: theme.spacing(4),
    },
    btn: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    center: {
      textAlign: "center",
    },
    subTitle: {
      marginBottom: theme.spacing(2),
    },
  })
);

export default useStyles;
