import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
    },
    paperDiv: {
      margin: theme.spacing(4),
    },
    btn: {
      marginLeft: theme.spacing(12),
      marginTop: "12%",
    },
    center: {
      textAlign: "center",
      justifyContent: "center",
    },
    subTitle: {
      marginBottom: theme.spacing(2),
    },
    inputsContainer: {
      textAlign: "center",
      justifyContent: "center",
    },
    inputs: {
      "& > *": {
        width: "45.5%",
        margin: theme.spacing(2),
      },
    },
    btnGenerate: {
      width: "5.5rem",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    pegarContainer: {
      margin: theme.spacing(2),
    },
    btnQrCode: {
      marginTop: "2%",
    },
    qrCode: {
      width: "50%",
    },
    addSucessQr: {
      marginTop: "1.5rem",
    },
  })
);

export default useStyles;
