import Axios from "axios";

let host: string = "https://api.lockyt.com.br";

if (process.env.NODE_ENV === "development") {
  host = "http://localhost:8080";
}

export default Axios.create({
  baseURL: host,
});
