import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Paper,
  IconButton,
  LinearProgress,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import GoogleMapReact from "google-map-react";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
import MaterialTable from "material-table";
import Marker from "components/Marker";

import AppBar from "components/AppBar";

import axios from "constants/axios";

import getToken from "functions/getToken";
import isEmail from "functions/isEmail";
import * as unityId from "functions/unityId";

import useStyles from "./styles";

interface State {
  _id: string;
  name: string;
}

interface Deliveries {
  delivered: boolean;
  status: string;
  deliveryNumber: string;
  cod: string;
  secundary?: boolean;
  secundaryProblem?: string;
  deliveryMan?: {
    name: string;
    email: string;
  };
  logs: [
    {
      reportedProblem: string;
      createdAt: string;
    }
  ];
}

interface Gps {
  center: {
    lat: number;
    lng: number;
  };
}

interface Board {
  lat: number;
  long: number;
  address: string;
  _id: string;
}

export default function Home() {
  const classes = useStyles();
  let [deliveries, setDeliveries] = useState<Deliveries[]>([]),
    [loading, setLoading] = useState<boolean>(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    [deliveryNumber, setDeliveryNumber] = useState(""),
    [userEmail, setUserEmail] = useState(""),
    [showMap, setShowMap] = useState(false),
    [gps, setGps] = useState<Gps>({
      center: {
        lat: -25,
        lng: -45,
      },
    }),
    [qrCode, setQrCode] = useState(""),
    [delivery, setDelivery] = useState(""),
    [boards, setBoards] = useState([]),
    [addSucessQr, setAddSucessQr] = useState("");

  const getDeliveries = useCallback(async () => {
    setLoading(true);
    axios
      .get("/enterprise/list/unity/deliveries/" + unityId.getUnityId(), {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(async ({ data }) => {
        const proc = data.forEach(async (val: Deliveries, index: number) => {
          let size: number = val.logs.length,
            { delivered, deliveryMan } = val,
            reportedProblem = val.logs[size - 1]?.reportedProblem;
          if (delivered) {
            data[index].status = "Entregue";
          } else if (deliveryMan) {
            data[index].status = "Em posse do entregador";
          } else {
            data[index].status = "Disponível";
          }
          if (reportedProblem) {
            data[index].secundary = true;
            data[index].secundaryProblem = reportedProblem;
          }
          data[index].createdAt = val.logs[size - 1]?.createdAt ? (
            moment(val.logs[size - 1]?.createdAt).format("DD/MM/YYYY HH:mm")
          ) : (
            <RemoveIcon />
          );
        });

        Promise.all([proc]).then(() => {
          setDeliveries(data);
          setLoading(false);
        });
      })
      .catch((error) => {
        setLoading(false);
        setAlert({ alert: true, msg: "Problema ao carregar encomendas" });
        console.log(error);
      });
  }, []);

  const getBoards = useCallback(async () => {
    axios
      .get("/enterprise/list/boards", {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        setBoards(data);
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setAlert({ alert: true, msg: "Problema ao carregar" });
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getDeliveries();
    getBoards();
  }, [getDeliveries, getBoards]);

  const procShowMap = async () => {
    if (!deliveryNumber || !userEmail) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "Preencha os 2 campos" });
    }
    if (!isEmail(userEmail)) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Email inválido", alert: true });
    }
    return setShowMap(!showMap);
  };

  const getLocation = async () => {
    navigator.geolocation.getCurrentPosition((data) => {
      setGps({
        center: { lat: data.coords.latitude, lng: data.coords.longitude },
      });
    });
  };

  useEffect(() => {
    getLocation();
  }, []);

  const renderMarker = (val: Board, index: number) => {
    let { lat, long, address, _id } = val;
    return <Marker lat={lat} lng={long} text={address} key={index} _id={_id} />;
  };

  const procQrCode = async () => {
    axios
      .get("/enterprise/get/qrCode/" + unityId.getUnityId() + "/" + delivery, {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        setQrCode(data);
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        if (err?.response?.data?.error?.code === "NoSuchKey") {
          setQrCode("");
          return setAlert({ alert: true, msg: "Pedido não encontrado" });
        }
        setAlert({ alert: true, msg: "Problema ao pegar QR-Code" });
      });
  };

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const downloadQrCode = async (img: string, cod: string) => {
    let element = document.createElement("a");
    let file = b64toBlob(img, "image/png");
    element.href = URL.createObjectURL(file);
    element.download = cod + ".png";
    element.click();
  };

  const markerClick = async (hoverKey: any, childProps: Board) => {
    axios
      .post(
        "/enterprise/add/delivery",
        {
          deliveryNumber: deliveryNumber,
          enterpriseUnity: unityId.getUnityId(),
          userEmail,
          board: childProps._id,
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(({ data }) => {
        setShowMap(false);
        setAddSucessQr(data);
      })
      .catch((error) => {
        let { status } = error.response;
        window.scrollTo(0, 0);
        console.log(error);
        switch (status) {
          case 404:
            return setAlert({ alert: true, msg: "Usuário não encontrado" });
          case 401:
            return setAlert({
              alert: true,
              msg: "Número do pedido já existe",
            });
          default:
            return setAlert({ alert: true, msg: "Erro ao gerar encomenda" });
        }
      });
  };

  return (
    <>
      <div>
        <AppBar
          hideDropdown={true}
          goTo="/dashboard/unities"
          pageName="Entregas"
        />
        {loading && <LinearProgress color="secondary" />}
        {alert.alert && (
          <Alert
            variant="filled"
            severity="error"
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setAlert({ msg: "", alert: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle> {alert.msg}</AlertTitle>
          </Alert>
        )}
        {alertSuccess.alert && (
          <Alert
            variant="filled"
            severity="success"
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setAlertSuccess({ msg: "", alert: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle> {alertSuccess.msg}</AlertTitle>
          </Alert>
        )}
        <Grid>
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.paper}>
              <div className={classes.paperDiv}>
                <MaterialTable
                  options={{ exportButton: true }}
                  columns={[
                    { title: "Número do pedido", field: "deliveryNumber" },
                    { title: "Código da entrega", field: "cod" },
                    { title: "Status", field: "status" },
                    {
                      title: "Recolhido pelo usuário",
                      field: "caught",
                      type: "boolean",
                    },
                    { title: "Nome entregador", field: "deliveryMan.name" },
                    {
                      title: "2º abertura",
                      field: "secundary",
                      type: "boolean",
                    },
                    {
                      title: "Problema para a 2º abertura",
                      field: "secundaryProblem",
                    },
                    {
                      title: "Última abertura",
                      field: "createdAt",
                    },
                  ]}
                  data={deliveries}
                  localization={{
                    header: {
                      actions: "Ações",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "registros",
                      labelRowsPerPage: "Registros por página:",
                      firstAriaLabel: "Primeira",
                      firstTooltip: "Primeira",
                      previousAriaLabel: "Anterior",
                      previousTooltip: "Anterior",
                      nextAriaLabel: "Próxima",
                      nextTooltip: "Próxima",
                      lastAriaLabel: "Última",
                      lastTooltip: "Última",
                    },
                    toolbar: {
                      exportTitle: "Exportar",
                      exportAriaLabel: "Exportar",
                      exportName: "Exportar CSV",
                      searchTooltip: "Pesquisar",
                      searchPlaceholder: "Pesquisar",
                    },
                    body: {
                      emptyDataSourceMessage: "Sem entregas",
                    },
                  }}
                  title="Entregas"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item>
            <Card className={classes.paperDiv}>
              <CardHeader title="Gerar pedido" />
              <CardContent className={classes.inputsContainer}>
                {!showMap && (
                  <div className={classes.inputs}>
                    <TextField
                      label="Número do pedido"
                      value={deliveryNumber}
                      fullWidth
                      onChange={(e) => setDeliveryNumber(e.target.value)}
                    />
                    <TextField
                      label="Email do usuário (deve possuir conta Lockyt)"
                      value={userEmail}
                      fullWidth
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <Button
                      size="small"
                      className={classes.btnGenerate}
                      color="secondary"
                      variant="contained"
                      onClick={() => procShowMap()}
                    >
                      Próximo
                    </Button>
                  </div>
                )}
                {showMap && (
                  <div style={{ height: "100vh", width: "100%" }}>
                    <div className={classes.center}>
                      <Button
                        size="small"
                        onClick={() => setShowMap(false)}
                        color="secondary"
                        variant="contained"
                      >
                        Voltar
                      </Button>
                      <Typography variant="h6" align="center">
                        Selecione a unidade para entrega clicando no marcador
                      </Typography>
                    </div>
                    <div
                      style={{
                        height: "80%",
                        width: "96%",
                        margin: "auto",
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
                        }}
                        defaultCenter={gps.center}
                        defaultZoom={14}
                        onChildClick={markerClick}
                      >
                        {boards.map((val, index) => renderMarker(val, index))}
                      </GoogleMapReact>
                    </div>
                  </div>
                )}
                {addSucessQr && (
                  <div className={classes.addSucessQr}>
                    <Typography variant="subtitle1" align="center">
                      Pedido adicionado com sucesso
                    </Typography>
                    <div className={classes.center}>
                      <img
                        src={"data:image/jpeg;base64," + addSucessQr}
                        className={classes.qrCode}
                        alt="qrCode"
                      />
                    </div>
                    <div className={classes.center}>
                      <Button
                        size="large"
                        className={classes.btnQrCode}
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          downloadQrCode(addSucessQr, deliveryNumber)
                        }
                      >
                        Baixar
                      </Button>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paperDiv}>
              <CardHeader title="Pegar QR-Code" />
              <CardContent className={classes.pegarContainer}>
                <TextField
                  label="Número do pedido (já existente)"
                  value={delivery}
                  onChange={(e) => setDelivery(e.target.value)}
                  fullWidth={true}
                />
                <div className={classes.center}>
                  <Button
                    size="medium"
                    className={classes.btnQrCode}
                    color="secondary"
                    variant="contained"
                    onClick={() => procQrCode()}
                  >
                    Buscar
                  </Button>
                </div>
                {qrCode && (
                  <>
                    <div className={classes.center}>
                      <img
                        src={"data:image/jpeg;base64," + qrCode}
                        className={classes.qrCode}
                        alt="qrCode"
                      />
                    </div>
                    <div className={classes.center}>
                      <Button
                        size="large"
                        className={classes.btnQrCode}
                        color="secondary"
                        variant="contained"
                        onClick={() => downloadQrCode(qrCode, delivery)}
                      >
                        Baixar
                      </Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
