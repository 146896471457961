import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useLocation } from "react-router-dom";
import copy from "clipboard-copy";

import AppBar from "../../../components/AppBar";

import axios from "../../../constants/axios";

import getToken from "../../../functions/getToken";
import * as unityId from "../../../functions/unityId";

import useStyles from "./styles";

interface State {
  _id: string;
  name: string;
  routes: {
    responseRoute: string;
  };
}

export default function Home() {
  const classes = useStyles();
  let [responseRoute, setResponseRoute] = useState(""),
    [loading, setLoading] = useState<boolean>(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    [unityToken, setUnityToken] = useState(""),
    location = useLocation<State>();

  const updateData = async () => {
    if (!responseRoute) {
      setAlert({
        alert: true,
        msg: "Por favor entre com a rota",
      });
    }
    setLoading(true);
    axios
      .put(
        "/enterprise/update/unity/routes",
        {
          responseRoute,
          unityId: unityId.getUnityId(),
        },
        {
          headers: {
            Authorization: "bearer " + getToken(),
          },
        }
      )
      .then(() => {
        setAlertSuccess({
          alert: true,
          msg: "Atualizado com sucesso",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setResponseRoute(location.state?.routes?.responseRoute || "");
  }, [location.state]);

  const showToken = async () => {
    setLoading(true);
    axios
      .get("/enterprise/get/unity/token/" + location.state._id, {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        setUnityToken(data.token);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          alert: true,
          msg: "Erro ao carregar token",
        });
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <AppBar
          hideDropdown={true}
          goTo="/dashboard/unities"
          pageName="Desenvolver"
        />
        {loading && <LinearProgress color="secondary" />}
        {alert.alert && (
          <Alert
            variant="filled"
            severity="error"
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setAlert({ msg: "", alert: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle> {alert.msg}</AlertTitle>
          </Alert>
        )}
        {alertSuccess.alert && (
          <Alert
            variant="filled"
            severity="success"
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => setAlertSuccess({ msg: "", alert: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle> {alertSuccess.msg}</AlertTitle>
          </Alert>
        )}
        <Grid>
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h4">
                Desenvolvedor - {location.state.name}
              </Typography>
              <div className={classes.paperDiv}>
                <Typography variant="h5" className={classes.subTitle}>
                  Rotas de resposta (POST)
                </Typography>
                <TextField
                  required
                  id="name"
                  label="Encomenda entregue"
                  value={responseRoute}
                  onChange={(e) => setResponseRoute(e.target.value)}
                  fullWidth
                />
                <div className={classes.center}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.btn}
                    onClick={updateData}
                  >
                    Salvar
                  </Button>
                </div>
              </div>
              <div className={classes.paperDiv}>
                <Typography variant="h5" className={classes.subTitle}>
                  Token de autenticação
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.subTitle}
                ></Typography>
                <Card>
                  <CardActionArea>
                    <CardContent>
                      <Typography
                        variant="body1"
                        component="p"
                        onClick={() => {
                          copy(unityToken).then(() => {
                            setAlertSuccess({ alert: true, msg: "Copiado!" });
                          });
                        }}
                      >
                        {unityToken || "**********************************"}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    {unityToken ? (
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => setUnityToken("")}
                      >
                        Ocultar
                      </Button>
                    ) : (
                      <Button size="small" color="primary" onClick={showToken}>
                        Mostrar
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
