import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 350,
      marginTop: "12%",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    btn: {
      padding: theme.spacing(1),
      textAlign: "center",
    },
    btnSecondary: {
      padding: theme.spacing(1),
      textAlign: "center",
    },
    btnSecondaryContainer: {
      textAlign: "center",
      marginTop: "3%",
    },
    backgroundImage: {
      minHeight: "100vh",
      height: "auto",
      display: "inherit",
      position: "relative",
      margin: "0",
      padding: "0",
      border: "0",
      alignItems: "center",
    },
    input: {
      marginBottom: "5%",
      marginTop: "5%",
    },
    btnForgot: {
      marignTop: "3%",
    },
  })
);

export default useStyles;
