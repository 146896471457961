import React, { useState, useEffect } from "react";

import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Theme,
  withStyles,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";

import AppBar from "../../../components/AppBar";

import useStyles from "./styles";
import { useLocation, useHistory } from "react-router-dom";

interface Data {
  trade_name: string;
  company_name: string;
  document: string;
  address: string;
  createdAt: string;
}

interface Location {
  enterprise: Data;
}

export default function NewEnterprise() {
  const classes = useStyles(),
    [data, setData] = useState<Data>(),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    history = useHistory(),
    location = useLocation<Location>();

  useEffect(() => {
    if (!location?.state?.enterprise) {
      return history.push("/dashboard/unities");
    }
    setData(location.state.enterprise);
  }, [location, history]);

  const mascaraCnpj = (valor: string): string => {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  };

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <>
      <AppBar
        hideDropdown={true}
        goTo="/dashboard/unities"
        pageName="Informações"
      />
      {alert.alert && (
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setAlert({ msg: "", alert: false })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle> {alert.msg}</AlertTitle>
        </Alert>
      )}
      <Paper elevation={3} className={classes.paper}>
        <Typography
          variant="h4"
          component="p"
          color="textPrimary"
          align="center"
        >
          Informações da empresa
        </Typography>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.paperContent}
        >
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Razão Social: <b>{data?.company_name || ""}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Endereço: <b>{data?.address || ""}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              CNPJ: <b>{mascaraCnpj(data?.document || "")}</b>
            </Typography>
          </Grid>
          <Typography variant="body1" color="textPrimary">
            Data de cadastro:{" "}
            <b>{moment(data?.createdAt).format("DD/MM/YYYY")}</b>
          </Typography>
          <Grid item>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit" align="center">
                    Para alterar esses dados entre em contato com a
                    Microsistemas
                  </Typography>
                </React.Fragment>
              }
            >
              <IconButton>
                <InfoIcon color="secondary" />
              </IconButton>
            </HtmlTooltip>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
