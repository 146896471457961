import React, { useState } from "react";

import {
  FormControl,
  Input,
  Grid,
  Button,
  CardContent,
  Card,
  InputLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  CardHeader,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

import axios from "constants/axios";

import AppBar from "../../../components/AppBar";

import useStyles from "./styles";

import image from "../../../assets/img/background/bg7.jpg";

export default function ForgotPwd() {
  const classes = useStyles();
  let [email, setEmail] = useState<string>(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [loading, setLoading] = useState<boolean>();
  let history = useHistory();

  const verify = async () => {
    if (!email) {
      return setAlert({ msg: "Campos faltando", alert: true });
    }
    setLoading(true);
    axios
      .post("/enterprise/user/forgotPassword", {
        email,
      })
      .then(({ data }) => {
        setLoading(false);
        history.push({
          pathname: "/login",
          state: {
            changes: "Enviado com sucesso! Verifique seu email",
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 404) {
          return setAlert({
            msg: "Usuário não encontrado",
            alert: true,
          });
        }
        setAlert({
          msg: "Erro ao pedir nova senha: " + error?.response?.data?.error,
          alert: true,
        });
        return console.log(error);
      });
  };

  return (
    <>
      <div
        className={classes.backgroundImage}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div>
          <AppBar />
          {loading ? <LinearProgress color="secondary" /> : <></>}
          {alert.alert ? (
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlert({ msg: "", alert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle> {alert.msg}</AlertTitle>
            </Alert>
          ) : (
            <></>
          )}

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Card className={classes.root}>
                <CardHeader
                  title="Esqueceu a senha?"
                  titleTypographyProps={{ align: "center" }}
                ></CardHeader>
                <CardContent>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="email">Email </InputLabel>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      size="small"
                      className={classes.btn}
                      onClick={verify}
                    >
                      Solicitar
                    </Button>
                    <div className={classes.btnSecondaryContainer}>
                      <Button
                        size="small"
                        className={classes.btnSecondary}
                        onClick={() => history.push("/login")}
                      >
                        Voltar ao login
                      </Button>
                    </div>
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
