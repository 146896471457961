import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
    },
    paperDiv: {
      margin: theme.spacing(4),
    },
    btn: {
      marginLeft: theme.spacing(12),
      marginTop: "12%",
    },
    center: {
      textAlign: "center",
    },
    subTitle: {
      marginBottom: theme.spacing(2),
    },
    input: {
      width: "150%",
    },
    inputsContainer: {
      margin: theme.spacing(3),
      flexDirection: "row",
    },
    btnDownload: {
      marginTop: "2%",
    },
    btnDownloadAnchor: {
      textDecoration: "none",
    },
  })
);

export default useStyles;
