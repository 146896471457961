import React from "react";

import useStyles from "./styles";
interface Props {}

const Footer: React.FC = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <table className={classes.wrapper}>
        <tbody>
          <tr>
            <td className={classes.wrapperTd}>
              <a
                href="https://lockyt.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/img/logos/lockytBranco.png")}
                  alt="Logo lockyt"
                  className={classes.logoLockyt}
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Footer;
