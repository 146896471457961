import React, { useState, useEffect } from "react";

import {
  FormControl,
  Input,
  Grid,
  Button,
  CardContent,
  Card,
  InputLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  CardHeader,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useParams } from "react-router-dom";

import axios from "constants/axios";

import AppBar from "../../../components/AppBar";

import useStyles from "./styles";

import image from "../../../assets/img/background/bg7.jpg";

interface Params {
  token: string;
}

export default function ResetPwd() {
  const classes = useStyles();
  let [email, setEmail] = useState<string>(""),
    [password, setPassword] = useState<string>(""),
    [token, setToken] = useState<string>(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [loading, setLoading] = useState<boolean>();
  let history = useHistory(),
    params = useParams<Params>();

  const verify = async () => {
    if (!email || !password) {
      return setAlert({ msg: "Campos faltando", alert: true });
    }
    setLoading(true);
    axios
      .post("/enterprise/user/resetPassword", {
        email,
        token,
        password,
      })
      .then(({ data }) => {
        setLoading(false);
        history.push({
          pathname: "/login",
          state: {
            changes: "Senha alterada com sucesso, faça login",
          },
        });
      })
      .catch((error) => {
        setAlert({
          msg: "Erro ao alterar: " + error?.response?.data?.error,
          alert: true,
        });
        setLoading(false);
        return console.log(error);
      });
  };

  useEffect(() => {
    if (!params.token) {
      return history.push("/login");
    }
    setToken(params.token);
  }, [params, history]);

  return (
    <>
      <div
        className={classes.backgroundImage}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div>
          <AppBar />
          {loading ? <LinearProgress color="secondary" /> : <></>}
          {alert.alert ? (
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlert({ msg: "", alert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle> {alert.msg}</AlertTitle>
            </Alert>
          ) : (
            <></>
          )}

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Card className={classes.root}>
                <CardHeader
                  title="Nova senha"
                  titleTypographyProps={{ align: "center" }}
                ></CardHeader>
                <CardContent>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="email">Email </InputLabel>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="password">Nova senha </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      size="small"
                      className={classes.btn}
                      onClick={verify}
                    >
                      Salvar nova senha
                    </Button>
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
