import React from "react";
import useStyles from "./styles";
import "./Marker.css"

interface Props {
  lat: number;
  lng: number;
  text: string;
  _id: string;
}

export default function Marker(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.markerDiv}>
      <img
        src={require("../../assets/img/markers/marker.png")}
        className="marker"
        alt="Marcador"
      />
    </div>
  );
}
