import React from "react";
import {
  IconButton,
  Typography,
  Button,
  CardContent,
  CardActions,
  Card,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";

import useStyles from "./styles";

interface Props {
  title: string;
  qtdAdm?: number;
  handleClick?(): void;
  btnTitle?: string;
  unityInfo?: string;
  unityAddress?: string;
  handleClickBtnDev?(): void;
  btnDevTitle?: string;
  btnDeliveriesTitle?: string;
  handleClickDeliveries?(): void;
  showUnityConf: boolean;
  unityConfClick?(): void;
}

export default function SimpleCard(props: Props) {
  let {
    title,
    qtdAdm,
    handleClick,
    btnTitle,
    unityInfo,
    unityAddress,
    handleClickBtnDev,
    btnDevTitle,
    handleClickDeliveries,
    btnDeliveriesTitle,
    showUnityConf,
    unityConfClick,
  } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        {unityInfo && (
          <Typography variant="body2" component="p" className={classes.text}>
            Descriçao: <b>{unityInfo}</b>
          </Typography>
        )}
        {unityAddress && (
          <Typography variant="body2" component="p" className={classes.text}>
            Endereço: <b>{unityAddress}</b>
          </Typography>
        )}
        {qtdAdm && (
          <Typography variant="body2" component="p" className={classes.text}>
            Número de administradores: {qtdAdm}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        {btnTitle && handleClick && (
          <Button
            variant="contained"
            size="small"
            onClick={handleClick}
            className={classes.btns}
          >
            {btnTitle}
          </Button>
        )}
        {btnDeliveriesTitle && handleClickDeliveries && (
          <Button
            variant="contained"
            size="small"
            onClick={handleClickDeliveries}
            className={classes.btns}
          >
            {btnDeliveriesTitle}
          </Button>
        )}
        {btnDevTitle && handleClickBtnDev && (
          <Button
            variant="contained"
            size="small"
            onClick={handleClickBtnDev}
            className={classes.btns}
          >
            {btnDevTitle}
          </Button>
        )}
        {showUnityConf && (
          <IconButton aria-label="delete" onClick={unityConfClick}>
            <SettingsIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
}
