import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import AppBar from "components/AppBar";
import Card from "components/Card";
import Footer from "components/Footer";

import axios from "constants/axios";

import logout from "functions/logOut";
import getToken from "functions/getToken";
import getAdmStatus from "functions/getAdmStatus";
import getEnterpriseId from "functions/getEntepriseId";
import * as unityId from "functions/unityId";

import useStyles from "./styles";

interface CardClick {
  _id: string;
  routes: string;
  name: string;
  info: string;
}

export default function Home() {
  let [unities, setUnities] = useState([]),
    history = useHistory();
  const classes = useStyles();

  const getData = useCallback(async () => {
    axios
      .get("/enterprise/list/enterprise/unities/" + getEnterpriseId(), {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        if (data) {
          setUnities(data.unities);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!getEnterpriseId()) {
      logout();
      return history.push("/login");
    }
    getData();
  }, [getData, history]);

  /* const handleDevClick = async (data: {
    _id: string;
    routes: string;
    name: string;
  }) => {
    history.push({
      pathname: "/dashboard/unity/dev",
      state: {
        _id: data._id,
        routes: data.routes,
        name: data.name,
      },
    });
  }; */

  const handleOptionClick = async (data: CardClick) => {
    unityId.setUnityId(data._id);
    history.push({
      pathname: "/dashboard/unity/options",
      state: {
        _id: data._id,
        name: data.name,
      },
    });
  };

  const handleDeliveriesClick = async (data: CardClick) => {
    unityId.setUnityId(data._id);
    history.push({
      pathname: "/dashboard/unity/deliveries",
      state: {
        _id: data._id,
        name: data.name,
      },
    });
  };

  const handleUnityConf = async (data: CardClick) => {
    unityId.setUnityId(data._id);
    history.push({
      pathname: "/dashboard/unity/config",
      state: data,
    });
  };

  const renderUnities = () => {
    if (unities) {
      return unities.map((val: any, index: number) => (
        <Grid item xs={6} style={{ minWidth: 300 }} key={index}>
          <Card
            title={val.name}
            handleClick={() => handleOptionClick(val)}
            btnTitle="Detalhes"
            btnDeliveriesTitle="Entregas"
            handleClickDeliveries={() => handleDeliveriesClick(val)}
            unityInfo={val.info}
            unityAddress={val.address}
            showUnityConf={getAdmStatus() ? true : false}
            unityConfClick={() => handleUnityConf(val)}
            /* btnDevTitle="Desenvolver"
            handleClickBtnDev={() => handleDevClick(val)} */
          />
        </Grid>
      ));
    }
    return <></>;
  };

  return (
    <>
      <div>
        <AppBar />
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          spacing={3}
          className={classes.gridContainer}
        >
          {renderUnities()}
        </Grid>
        <Footer />
      </div>
    </>
  );
}
