import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        minWidth: "30ch",
        marginTop: theme.spacing(5),
      },
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(3),
      color: theme.palette.text.secondary,
    },
    btn: {
      marginTop: theme.spacing(2),
    },
    paperContent: {
      marginTop: "2%",
    },
  })
);
export default useStyles;
