export const getUnityId = (): string | null => {
  return sessionStorage.getItem("@unity");
};

export const setUnityId = (value: string) => {
  return sessionStorage.setItem("@unity", value);
};

export const delUnityId = () => {
  return sessionStorage.removeItem("@unity");
};
