import React, { useState, useEffect, useCallback } from "react";

import {
  FormControl,
  Input,
  Grid,
  CardContent,
  Card,
  InputLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  CardHeader,
  Typography,
  Button,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useParams } from "react-router-dom";

import axios from "constants/axios";

import AppBar from "../../../components/AppBar";

import useStyles from "./styles";

import image from "../../../assets/img/background/bg7.jpg";

interface Params {
  token: string;
}

export default function ResetPwd() {
  const classes = useStyles();
  let [email, setEmail] = useState<string>(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [error, setError] = useState(false),
    [loading, setLoading] = useState<boolean>();
  let history = useHistory(),
    params = useParams<Params>();

  const verify = useCallback(
    async (token: string) => {
      setLoading(true);
      axios
        .post("/enterprise/user/validateEmail", {
          token,
        })
        .then(() => {
          setLoading(false);
          history.push({
            pathname: "/login",
            state: {
              changes: "Conta ativada com sucesso, faça login!",
            },
          });
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
          setAlert({
            msg: "Erro ao ativar: " + error?.response?.data?.error,
            alert: true,
          });
          return console.log(error);
        });
    },
    [history]
  );

  const resend = async () => {
    setLoading(true);
    axios
      .post("/enterprise/user/resendValidateEmail", {
        email,
      })
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/login",
          state: {
            changes:
              "Enviamos novamente para o seu email uma mensagem de ativação de conta.",
          },
        });
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        setAlert({
          msg: "Erro ao enviar: " + error?.response?.data?.error,
          alert: true,
        });
        return console.log(error);
      });
  };

  useEffect(() => {
    if (!params.token) {
      return history.push("/login");
    }
    verify(params.token);
  }, [params, history, verify]);

  return (
    <>
      <div
        className={classes.backgroundImage}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div>
          <AppBar />
          {loading ? <LinearProgress color="secondary" /> : <></>}
          {alert.alert ? (
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlert({ msg: "", alert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle> {alert.msg}</AlertTitle>
            </Alert>
          ) : (
            <></>
          )}

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Card className={classes.root}>
                <CardHeader
                  title="Ativação de conta"
                  titleTypographyProps={{ align: "center" }}
                ></CardHeader>
                {error ? (
                  <CardContent>
                    <FormGroup>
                      <FormControl className={classes.input}>
                        <InputLabel htmlFor="email">Email </InputLabel>
                        <Input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Typography variant="caption" align="center">
                          Pedir novamente
                        </Typography>
                      </FormControl>
                      <Button
                        size="small"
                        className={classes.btn}
                        onClick={resend}
                      >
                        Pedir novamente
                      </Button>
                    </FormGroup>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography variant="body1" align="center">
                      Processando ativação
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
