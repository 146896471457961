import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AddIcon from "@material-ui/icons/Add";
import Badge from "@material-ui/core/Badge";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useHistory, useLocation } from "react-router-dom";

import useStyles from "./styles";

//import Drawer from "../Drawer";

import isAuthenticated from "../../functions/isAuthenticated";
import logout from "../../functions/logOut";
import getAdmStatus from "functions/getAdmStatus";

import getToken from "../../functions/getToken";

import axios from "../../constants/axios";

interface Props {
  hideDropdown?: boolean;
  goTo?: string;
  pageName?: string;
}

interface Enterprise {
  trade_name: string;
  company_name: string;
  _id: string;
  document: string;
  address: string;
}

export default function MenuAppBar(props: Props) {
  const classes = useStyles();
  const { hideDropdown, goTo, pageName } = props;
  const [auth] = React.useState(isAuthenticated());
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //const [drawer, setDrawer] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const history = useHistory(),
    location = useLocation();

  let [enterprise, setEnterprise] = useState<Enterprise>();

  const getData = async () => {
    axios
      .get("/enterprise/list/user/enterprise", {
        headers: {
          Authorization: "bearer " + getToken(),
        },
      })
      .then(({ data }) => {
        setEnterprise(data.enterprise);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      getData();
    }
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* const handleDrawer = async () => {
    setDrawer(!drawer);
  }; */

  const handleLogout = async () => {
    if (logout()) {
      history.push("/login");
    }
  };

  const handleGoBack = async () => {
    history.push({
      pathname: goTo || "",
      state: enterprise,
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        {auth && (
          <Toolbar>
            <div className={classes.titleContainer}>
              {enterprise && !hideDropdown && (
                <Typography variant="h6" className={classes.title}>
                  {enterprise?.trade_name.toUpperCase()}
                </Typography>
              )}
              {hideDropdown && goTo && (
                <div className={classes.arrowHeader}>
                  <Button
                    onClick={handleGoBack}
                    startIcon={<ArrowBackIcon />}
                    className={classes.backBtn}
                    size="large"
                  >
                    {pageName || ""}
                  </Button>
                </div>
              )}
            </div>
            <div>
              {location.pathname === "/dashboard/unities" && getAdmStatus() && (
                <Button
                  aria-label="adicionar nova unidade"
                  className={classes.btnNewUnity}
                  onClick={() => history.push("/dashboard/new/unity")}
                >
                  <Badge variant="dot" color="error">
                    <AddIcon />
                  </Badge>
                  <Typography variant="body2" className={classes.newUnity}>
                    Nova unidade
                  </Typography>
                </Button>
              )}

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                className={classes.menuButton}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                {location.pathname !== "/dashboard/main" && (
                  <MenuItem
                    onClick={() =>
                      history.push({
                        pathname: "/dashboard/report/enterprise",
                        state: {
                          enterprise,
                        },
                      })
                    }
                    className={classes.menuItem}
                  >
                    Relatório
                  </MenuItem>
                )}
                {location.pathname !== "/dashboard/main" && getAdmStatus() && (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() =>
                      history.push({
                        pathname: "/dashboard/config/enterprise",
                        state: {
                          enterprise,
                        },
                      })
                    }
                  >
                    Informações da empresa
                  </MenuItem>
                )}
                <MenuItem className={classes.menuItem} onClick={handleLogout}>
                  Sair
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        )}
      </AppBar>
    </div>
  );
}
